import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CompetitionBlock from "../../components/MainStats/CompetitionBlock"
import LastUpdated from "../../components/LastUpdated/LastUpdated"

const Finals = ({data}) => (
  <Layout>
      <SEO
        title={"Goals and Stats in Finals - Messi vs Ronaldo"}
        description={`Messi and Ronaldo have played in countless finals throughout their careers, but who has scored more goals made more assists in these big games?`}
        canonicalPath={`/detailed-stats/record-in-finals/`}
      />

      <h1>
        Goals and Stats in Finals <span className="sr-only">- Messi vs Ronaldo</span>
      </h1>

      

      <CompetitionBlock competition="All Career Finals" type="all" mdata={data.allSheetMessiAllTimeStats.edges} rdata={data.allSheetRonaldoAllTimeStats.edges} smallPrint="Appearance stats include two legged finals" />

      <CompetitionBlock competition="All Club Finals" type="all" mdata={data.allSheetMessiAllTimeStats.edges} rdata={data.allSheetRonaldoAllTimeStats.edges} smallPrint="Appearance stats include two legged finals" />

      <CompetitionBlock competition="All International Finals" type="int" mdata={data.allSheetMessiAllTimeStats.edges} rdata={data.allSheetRonaldoAllTimeStats.edges} />

      <LastUpdated type="Stats" />
      
    </Layout>
)
export const query = graphql`
  query {
    allSheetMessiAllTimeStats(filter: {pgKey: {regex: "/finals/"}}) {
      edges {
        node {
          id
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
          shotsPerGoal
          freeKickAttempts
          bigChancesCreated
          xg
          fkc
          xgc
        }
      }
    }
    allSheetRonaldoAllTimeStats(filter: {pgKey: {regex: "/finals/"}}) {
      edges {
        node {
          id
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
          shotsPerGoal
          freeKickAttempts
          bigChancesCreated
          xg
          fkc
          xgc
        }
      }
    }
  }
`

export default Finals
